<template lang="pug">
v-list-item.pl-6.my-0(:active="isActive", @click="goTo(item.route)", :title="item.name", color="secondary")
	template(v-slot:append)
		v-icon(v-if="isFolder", :icon="iconDirection")
.ml-4(v-show="isFolderOpen", v-if="isFolder")
	BaseTreeMenuItem(v-for="child in item.children", :item="child")
</template>

<script>
export default {
	props: ['item'],
	data() {
		return {
			isFolderOpen: false
		};
	},
	computed: {
		currentRoute() {
			return this.$route.path;
		},
		isFolder() {
			return this.item.children && this.item.children.length;
		},
		iconDirection() {
			return this.isFolderOpen ? 'arrow_drop_up' : 'arrow_drop_down';
		},
		isActive() {
			return this.currentRoute === this.item.route;
		}
	},
	watch: {
		isActive: {
			handler(val) {
				if (val && this.$parent.isFolder) {
					this.openParentFolders(this.$parent);
				}
			},
			immediate: true
		}
	},
	methods: {
		goTo(route) {
			if (this.isFolder) {
				this.isFolderOpen = !this.isFolderOpen;
			} else if (route) {
				this.$router.push(route);
			}
		},
		openParentFolders($parent) {
			if ($parent.isFolder) {
				$parent.isFolderOpen = true;
				if ($parent.$parent) this.openParentFolders($parent.$parent);
			}
		}
	}
};
</script>
